<template>
  <div class="login">
    <div class="login-content">
      <div class="login-content_logo">
        LOGO
      </div>
      <h6 class="login-content_title mt-2">Đăng nhập đến Admin</h6>
      <div
        role="alert"
        v-bind:class="{ show: errors.length }"
        class="alert fade alert-danger mt-5"
        v-if="errors"
      >
        <div class="alert-text">
          Bạn đã nhập sai Email hoặc mã pin
        </div>
      </div>
      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            id="input-1"
            v-model="$v.form.email.$model"
            type="email"
            required
            class="mt-5"
            placeholder="Email"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback" class="text-left">
            Sai định dạng số điện thoại
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <b-form-input
            id="input-2"
            v-model="$v.form.password.$model"
            type="password"
            required
            placeholder="Pass"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-2-live-feedback" class="text-left">
            Mã code gồm 6 kí tự
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="text-right mt-4 pt-1">
          <router-link to="/forgot-pass">Quên mã Pin?</router-link>
        </div>
        <b-button type="submit" variant="primary" class="w-100 mt-4"
          >Đăng nhập
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<style lang="scss">
@import "./Login.scss";
</style>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { minLength, maxLength, required } from "vuelidate/lib/validators";
import { UserRole } from "./../../constans";

export default {
  mixins: [validationMixin],
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
        loading: false
      },
      errorNumber: false
    };
  },

  validations: {
    form: {
      email: {
        required
      },
      password: {
        minLength: minLength(6),
        maxLength: maxLength(6)
      }
    }
  },

  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },

  created() {
    this.$store.dispatch("removeErrorLogin");
  },

  methods: {
    onSubmit() {
      const dataBody = {
        email: this.form.email,
        password: this.form.password
      };

      this.$store.dispatch("login", dataBody).then(() => {
        this.$router.push("/dashboard");
      });
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
